import type * as maps from '@contentya/maps-interface';
import type { LngLatLike, LngLatLiteral } from '@contentya/lnglat-coords';
import { Map } from './map';
import { ggLatLng } from './coords';
import { ggMarkerOptions } from './markeroptions';
import { lngLatLiteral } from '@contentya/lnglat-coords';

export class Marker implements maps.MarkerAdapter<google.maps.Marker> {
    _marker: google.maps.Marker;

    constructor(marker: google.maps.Marker) {
        this._marker = marker;
    }

    adaptee(): google.maps.Marker {
        return this._marker;
    }

    setPosition(position: LngLatLike | null): this {
        this._marker.setPosition(ggMarkerPosition(position));
        return this;
    }

    getPosition(): LngLatLiteral | null | undefined {
        const position = this._marker.getPosition();
        return position == null ? position : lngLatLiteral(position);
    }

    addToMap(map: maps.Map): this {
        if (map instanceof Map) {
            this._marker.setMap(map.adaptee());
        }
        return this;
    }

    removeFromMap(): this {
        this._marker.setMap(null);
        return this;
    }
}

export function marker(options?: maps.MarkerOptions): Marker {
    if (typeof options === 'undefined') {
        return new Marker(new google.maps.Marker());
    }

    const ggOpts = ggMarkerOptions(options);
    return new Marker(new google.maps.Marker(ggOpts));
}

export function ggMarkerPosition(
    position?: LngLatLike | null | undefined
): google.maps.LatLng | google.maps.LatLngLiteral | null {
    if (position == null) {
        return null;
    }
    return ggLatLng(position);
}
