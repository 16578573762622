import type { PointLike } from '@contentya/maps-point';
import { pointTupleImpl } from '@contentya/maps-point';

export function ggPoint(point: PointLike): google.maps.Point {
    if (point instanceof google.maps.Point) {
        return point;
    }
    return new google.maps.Point(...pointTupleImpl('ggPoint()', point));
}

export function compatPointLike(point: google.maps.Point): PointLike {
    return point;
}
