/* istanbul ignore file */

(() => {
    if (!('google' in global) || typeof global.google != 'object' || !('maps' in global.google)) {
        const u = 'https://developers.google.com/maps/documentation/javascript/load-maps-js-api';
        console.log(`google.maps not loaded yet, see ${u} for more info`);
    }
})();

export * from '.';
