import type * as maps from '@contentya/maps-interface';
import type { LngLatLike, LngLatLiteral } from '@contentya/lnglat-coords';
import { Easing, Tween, update } from '@tweenjs/tween.js';
import { ggLatLng } from './coords';
import { ggMapOptions } from './mapoptions';
import { ggCameraOptions } from './cameraoptions';
import { lngLatLiteral } from '@contentya/lnglat-coords';

export class Map implements maps.MapAdapter<google.maps.Map> {
    private readonly _map: google.maps.Map;

    constructor(map: google.maps.Map) {
        this._map = map;
    }

    static crow(lng1: number, lat1: number, lng2: number, lat2: number): number {
        function rad(x: number) {
            return (x * Math.PI) / 180.0;
        }

        const R = 6371;
        const slat = Math.sin(rad(lat2 - lat1) / 2);
        const slng = Math.sin(rad(lng2 - lng1) / 2);
        const clat1 = Math.cos(rad(lat1));
        const clat2 = Math.cos(rad(lat2));

        const a = slat * slat + slng * slng * clat1 * clat2;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    }

    flyTo(options: maps.FlyToOptions): this {
        const map = this._map;

        type Frame = Omit<google.maps.CameraOptions, 'center'> & { center?: google.maps.LatLngLiteral };
        const beg: Frame = {};
        const end: Frame = {};

        const adjusted = ggCameraOptions(options);

        const zbeg = map.getZoom() || 9;
        const zend = adjusted?.zoom || zbeg;

        if ('center' in adjusted && adjusted.center !== undefined) {
            beg.center = map.getCenter()?.toJSON() || { lng: 0, lat: 0 };
            end.center = new google.maps.LatLng(adjusted.center).toJSON();
        }

        if ('zoom' in adjusted && adjusted.zoom !== undefined) {
            beg.zoom = zbeg;
            end.zoom = adjusted.zoom;
        }

        if ('heading' in adjusted && adjusted.heading !== undefined) {
            beg.heading = map.getHeading() || 0;
            end.heading = adjusted.heading;
        }

        if ('tilt' in adjusted && adjusted.tilt !== undefined) {
            beg.tilt = map.getTilt() || 0;
            end.tilt = adjusted.tilt;
        }


        const easing = options?.easing || Easing.Quadratic.Out;
        const duration = options?.duration || 5000;


        // Current viewport
        if ('center' in end && end.center !== undefined && 'center' in beg && beg.center !== undefined) {
            const curbb = map.getBounds();
            const allbb = map.getBounds();
            // Compute intermediate zoom by comparing diagonals of two viewports. The initial (current) viewport and a
            // viewport necessary to enclose current center of view and the destination center point.
            if (curbb !== undefined && allbb !== undefined) {
                allbb.extend(end.center);
                const cursw = curbb.getSouthWest();
                const curne = curbb.getNorthEast();
                const allsw = allbb.getSouthWest();
                const allne = allbb.getNorthEast();
                const curdiag = Map.crow(cursw.lng(), cursw.lat(), curne.lng(), curne.lat());
                const alldiag = Map.crow(allsw.lng(), allsw.lat(), allne.lng(), allne.lat());

                if (alldiag > 2 * curdiag) {
                    const zm = zbeg - (Math.log2(alldiag) - Math.log2(curdiag));

                    const mid1 = { ... beg, zoom: zm };
                    const mid2 = { ... end, zoom: zm };

                    mid1.center = {
                        lng: (beg.center.lng * 0.95 + end.center.lng * 0.05),
                        lat: (beg.center.lat * 0.95 + end.center.lat * 0.05)
                    };

                    mid2.center = {
                        lng: (end.center.lng * 0.95 + beg.center.lng * 0.05),
                        lat: (end.center.lat * 0.95 + beg.center.lat * 0.05)
                    };

                    const tween1 = new Tween(beg);
                    const tween2 = new Tween(mid1);
                    const tween3 = new Tween(mid2);

                    tween1.to(mid1, duration * 0.4).easing(easing).onUpdate((cur) => { map.moveCamera(cur); });
                    tween2.to(mid2, duration * 0.2).easing(easing).onUpdate((cur) => { map.moveCamera(cur); });
                    tween3.to(end, duration * 0.4).easing(easing).onUpdate((cur) => { map.moveCamera(cur); });

                    tween1.chain(tween2);
                    tween2.chain(tween3);

                    tween1.start();

                    function animate(time: number) {
                        window.requestAnimationFrame(animate);
                        update(time);
                    }

                    window.requestAnimationFrame(animate);

                    return this;
                }
            }
        }

        const tween = new Tween(beg);
        tween
            .to(end, duration)
            .easing(easing)
            .onUpdate((cur) => {
                map.moveCamera(cur);
            })
            .start();

        function animate(time: number) {
            window.requestAnimationFrame(animate);
            update(time);
        }

        window.requestAnimationFrame(animate);

        return this;
    }

    getHeading(): number | undefined {
        return this._map.getHeading();
    }

    getCenter(): LngLatLiteral | undefined {
        const center = this._map.getCenter();
        return center === undefined ? undefined : lngLatLiteral(center);
    }

    getContainer(): HTMLElement {
        return this._map.getDiv();
    }

    getMaxZoom(): number | undefined {
        /* the property exists in google.maps.Map, but is undocumented */
        return (this._map as { maxZoom?: number }).maxZoom;
    }

    getMinZoom(): number | undefined {
        /* the property exists in google.maps.Map, but is undocumented */
        return (this._map as { minZoom?: number }).minZoom;
    }

    getTilt(): number | undefined {
        return this._map.getTilt();
    }

    getZoom(): number | undefined {
        return this._map.getZoom();
    }

    adaptee(): google.maps.Map {
        return this._map;
    }

    panTo(...[coords]: [LngLatLike] | [LngLatLike, maps.PanOptions]): this {
        this._map.panTo(ggLatLng(coords));
        return this;
    }

    setCenter(center: LngLatLike): this {
        this._map.setCenter(ggLatLng(center));
        return this;
    }

    setHeading(heading: number): this {
        this._map.setHeading(heading);
        return this;
    }

    setMaxZoom(maxZoom?: number | null | undefined): this {
        this._map.setOptions({ maxZoom });
        return this;
    }

    setMinZoom(minZoom?: number | null | undefined): this {
        this._map.setOptions({ minZoom });
        return this;
    }

    setTilt(tilt: number): this {
        this._map.setTilt(tilt);
        return this;
    }

    setZoom(zoom: number): this {
        this._map.setZoom(zoom);
        return this;
    }
}

export function map(container: HTMLElement | string, options?: maps.MapOptions): Map {
    let element: HTMLElement | null;
    if (typeof container === 'string') {
        element = document.getElementById(container);
        if (element == null) {
            throw new Error(`html element with id="${container}" not found`);
        }
    } else {
        element = container;
    }

    if (options === undefined) {
        return new Map(new google.maps.Map(element));
    }

    return new Map(new google.maps.Map(element, ggMapOptions(options)));
}
