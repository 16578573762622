import type { LngLatBoundsLike } from '@contentya/lnglat-bounds';
import { lngLatBoundsLiteralImpl } from '@contentya/lnglat-bounds';

type GGLatLngBoundsLike = google.maps.LatLngBoundsLiteral | google.maps.LatLngBounds;

export function ggLatLngBounds(bounds: LngLatBoundsLike): google.maps.LatLngBounds;
export function ggLatLngBounds(bounds: unknown): google.maps.LatLngBounds {
    if (bounds instanceof google.maps.LatLngBounds) {
        return bounds;
    }

    return new google.maps.LatLngBounds(lngLatBoundsLiteralImpl('ggLatLngBounds()', bounds));
}

export function compatLngLatBoundsLike(bounds: GGLatLngBoundsLike): GGLatLngBoundsLike {
    return bounds;
}
