import type { LngLatLike } from '@contentya/lnglat-coords';
import { latLngTupleImpl } from '@contentya/lnglat-coords';

type GGLatLngLike = google.maps.LatLngLiteral | google.maps.LatLng;

export function ggLatLng(coords: LngLatLike): GGLatLngLike {
    if (coords instanceof google.maps.LatLng) {
        return coords;
    }
    return new google.maps.LatLng(...latLngTupleImpl('ggLatLng()', coords));
}

export function compatLngLatLike(coords: GGLatLngLike): LngLatLike {
    return coords;
}
