import type * as maps from '@contentya/maps-interface';
import { ggLatLng } from './coords';

type GGMapOptions<T extends maps.MapOptions> = Omit<T, 'center' | 'bearing' | 'pitch'> & google.maps.MapOptions;

export function ggMapOptions<T extends maps.MapOptions>(options: T): GGMapOptions<T> {
    const { center, bearing, pitch, ...rest } = options;
    const adjusted: GGMapOptions<T> = rest;

    if (center !== undefined) {
        adjusted.center = center === null ? center : ggLatLng(center);
    }

    if (bearing !== undefined && !('heading' in adjusted)) {
        adjusted.heading = bearing;
    }

    if (pitch !== undefined && !('tilt' in adjusted)) {
        adjusted.tilt = pitch;
    }

    return adjusted;
}
