(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("TWEEN"));
	else if(typeof define === 'function' && define.amd)
		define(["TWEEN"], factory);
	else if(typeof exports === 'object')
		exports["contentya"] = factory(require("TWEEN"));
	else
		root["contentya"] = root["contentya"] || {}, root["contentya"]["googlemaps"] = factory(root["TWEEN"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__701__) => {
return 