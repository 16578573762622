import type * as maps from '@contentya/maps-interface';
import { ggLatLng } from './coords';

type GgCameraOptions<T extends maps.CameraOptions> = Omit<T, 'bearing' | 'center' | 'pitch'> &
    google.maps.CameraOptions;

export function ggCameraOptions<T extends maps.CameraOptions>(options: T): GgCameraOptions<T> {
    const { bearing, center, pitch, ...rest } = options;
    const adjusted: GgCameraOptions<T> = rest;

    if (pitch != null && !('tilt' in adjusted)) {
        adjusted.tilt = pitch;
    }

    if (bearing !== undefined && !('heading' in adjusted)) {
        adjusted.heading = bearing;
    }

    if (center !== undefined) {
        adjusted.center = ggLatLng(center);
    }

    return adjusted;
}
