import type * as maps from '@contentya/maps-interface';
import { Map } from './map';
import { ggLatLng } from './coords';
import { ggPoint } from './point';

type GGMarkerOptions<T extends maps.MarkerOptions> = Omit<
    T,
    'anchorPoint' | 'animation' | 'icon' | 'label' | 'map' | 'position' | 'shape'
> &
    google.maps.MarkerOptions;

export function ggMarkerOptions<T extends maps.MarkerOptions>(options: T): GGMarkerOptions<T> {
    const { anchorPoint, animation, icon, label, map, position, shape, ...rest } = options;
    const adjusted: GGMarkerOptions<T> = rest;

    if (anchorPoint != null) {
        adjusted.anchorPoint = ggPoint(anchorPoint);
    } else if ('anchorPoint' in options) {
        adjusted.anchorPoint = anchorPoint;
    }

    if (animation === null) {
        adjusted.animation = animation;
    } else {
        switch (animation) {
            case 'bounce':
                adjusted.animation = google.maps.Animation.BOUNCE;
                break;
            case 'drop':
                adjusted.animation = google.maps.Animation.DROP;
                break;
        }
    }

    console.assert(icon === undefined, 'ggMarkerOptions(): MarkerOptions.icon is not implemented yet');
    console.assert(label === undefined, 'ggMarkerOptions(): MarkerOptions.label is not implemented yet');

    if (typeof map !== 'undefined' && map instanceof Map) {
        adjusted.map = map.adaptee();
    }

    if (position != null) {
        adjusted.position = ggLatLng(position);
    } else if ('position' in options) {
        adjusted.position = position;
    }

    console.assert(shape === undefined, 'ggMarkerOptions(): MarkerOptions.shape is not implemented yet');

    return adjusted;
}
